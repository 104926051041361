<script setup></script>

<template>
  <footer class="footer__wrap">
    <div class="footer__inner">
      <div class="footer__logo">
        <div class="logo-lotteria bl">
          <a href="/"><span class="blind">LOTTERIA</span></a>
        </div>
      </div>
      <div class="footer__copy">
        COPYRIGHT © 2025 LOTTE GRS CO.,LTD ALL RIGHT RESERVED.
      </div>
    </div>
  </footer>
</template>

<style scoped>
.footer__logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  width: 45%;
  padding-right: 3rem;
}

.logo-lotteria,
.logo-riasans {
  padding: 0;
  margin: 0;
}
</style>
