<script setup></script>

<template>
  <section class="layout__wrap download">
    <div class="layout__inner">
      <div class="riasans-detail__wrap type-white">
        <div class="riasans-detail__star v2">
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
        </div>
        <div class="riasans-detail__txt">
          <p>
            <a href="/download/RiaSans.zip" download="RiaSans.zip">
              리아체 다운로드
            </a>
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
/* DownloadSection 관련 스타일 정의 */
</style>
