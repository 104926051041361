<template>
  <div id="app">
    <noscript><strong>스크립트를 활성화 해주세요.</strong></noscript>
  </div>
</template>

<script>
export default {
  name: "App",
  mounted() {
    // Google Tag Manager 설정
    window.dataLayer = window.dataLayer || [];

    function gtag() {
      window.dataLayer.push(arguments);
    }

    gtag("js", new Date());
    gtag("config", "UA-252695655-1");
    gtag("config", "G-4CTW2VRXFH");
    gtag("config", "G-3S1ZD2LVZD");

    this.loadScript("/assets/js/chunk-vendors-restore.js", () => {
      this.loadScript("/assets/js/app-restore.js", this.addHeader); // 마지막에 헤더를 추가
    });

    this.loadScript("/assets/js/gsap.min.js", () => {
      this.loadScript("/assets/js/ScrollTrigger.min.js", () => {
        this.loadScript("/assets/js/ScrollSmoother.min.js", () => {
          if (
            typeof gsap !== "undefined" &&
            typeof ScrollTrigger !== "undefined" &&
            typeof ScrollSmoother !== "undefined"
          ) {
            gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

            const progressDiv = document.querySelector(".progress__inner");
            ScrollTrigger.create({
              onUpdate({ progress }) {
                if (progressDiv) {
                  progressDiv.style.width = `${(progress * 100).toFixed()}%`;
                }
              },
            });
          } else {
            console.error(
              "Failed to load GSAP, ScrollTrigger, or ScrollSmoother."
            );
          }
        });
      });
    });
  },
  methods: {
    loadScript(src, callback) {
      const script = document.createElement("script");
      script.src = src;
      script.defer = true;
      script.onload = callback; // 스크립트 로드 후 콜백 실행
      document.body.appendChild(script);
    },

    addHeader() {
      // 동적으로 헤더를 추가
      const headerHTML = `
        <header class="header__wrap">
          <div class="header__inner">
            <div class="header__logo">
              <h1 class="logo-lotteria bl">
                <a href="/"><span class="blind">LOTTERIA</span></a>
              </h1>
              <h2 class="logo-chobddag">
                <a href="/chobddag"><span class="blind">CHAB DDAG</span></a>
              </h2>
            </div>
            <div class="header__insta">
              <a href="https://www.instagram.com/lotteria_kr/">
                <span class="blind">인스타그램</span>
              </a>
            </div>
          </div>
          <div class="progress__bar">
            <div class="progress__inner"></div>
          </div>
        </header>
      `;

      const appDiv = document.getElementById("app");
      appDiv.insertAdjacentHTML("afterbegin", headerHTML);

      const headerHeight = document.querySelector(".header__wrap").offsetHeight;

      if (window.innerWidth <= 768) {
        appDiv.style.paddingTop = `${headerHeight / 2}px`;
      } else {
        appDiv.style.paddingTop = `${headerHeight}px`;
      }

      const menuLogoWrapper = document.querySelector(".menuLogoWrapper");
      const toggleMenuWrapper = document.querySelector(".toggleMenuWrapper");

      if (menuLogoWrapper) {
        menuLogoWrapper.innerHTML = "";
        const imageHTML = `
          <img src="/lotteria-icon-new.png" alt="Lotteria Icon"/>
        `;
        menuLogoWrapper.insertAdjacentHTML("beforeend", imageHTML);
        menuLogoWrapper.style.marginTop = `${headerHeight}px`;
      }

      if (toggleMenuWrapper) {
        toggleMenuWrapper.style.marginTop = `${headerHeight}px`;
      }
      const menuItems = document.querySelectorAll(".experienceMenu-item");

      if (window.innerWidth <= 768) {
        menuItems.forEach((item) => {
          item.style.height = "60px";
        });
      }

      window.addEventListener("resize", () => {
        if (window.innerWidth <= 768) {
          menuItems.forEach((item) => {
            item.style.height = "60px";
          });
        } else {
          // 모바일이 아닌 경우에 스타일 초기화
          menuItems.forEach((item) => {
            item.style.height = "";
          });
        }
      });

      const chabWrapper = document.querySelector(".chabWrapper");
      if (chabWrapper) {
        chabWrapper.style.setProperty("position", "relative", "important");
      }

      const circleWrapper = document.querySelector(".circleWrapper");
      if (circleWrapper) {
        circleWrapper.style.setProperty("position", "sticky", "important");
        circleWrapper.style.setProperty("top", "0", "important");
        circleWrapper.style.setProperty("z-index", "1000", "important");
        circleWrapper.style.setProperty("overflow", "visible", "important");
      }

      const board = document.querySelector(".board");
      if (board) {
        board.style.setProperty("overflow", "visible", "important");
      }
    },
  },
};
</script>

<style scoped>
@import url("../assets/css/app.d8607b07.css");

.header__wrap {
  background-color: #fff;
  padding: 1rem 0;
}

.header__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__logo h1,
.header__logo h2 {
  margin: 0;
}

.progress__bar {
  height: 4px;
  background-color: #f0f0f0;
}

.progress__inner {
  width: 0;
  height: 100%;
  background-color: #ff0000;
}
</style>
