<script setup>
import { onMounted } from "vue";
import loadScripts from "@/utils/loadScripts";

onMounted(() => {
  loadScripts();
});
</script>

<template>
  <div id="wrap">
    <!-- s:header -->
    <header class="header__wrap">
      <div class="header__inner">
        <div class="header__logo">
          <h1 class="logo-lotteria bl">
            <a href="/"><span class="blind">LOTTERIA</span></a>
          </h1>
        </div>
        <div class="header__insta">
          <a href="https://www.instagram.com/lotteria_kr/">
            <span class="blind">인스타그램</span>
          </a>
        </div>
      </div>
    </header>
    <!-- //e:header -->

    <div class="main__wrap">
      <div class="main__inner">
        <div class="main-btn main-btn__riasans">
          <div class="main-btn__riasans-wrapper">
            <p class="ria-title">RIA</p>
            <div class="ria-desc-wrapper">
              <p class="ria-subtitle">
                ExtraBold에 이어 <br />
                Bold, Regular 완전체로 돌아왔다!
              </p>
              <img
                class="ria-badge"
                src="../assets/images/common/badge_ria.png"
                alt="폰트도 세트메뉴야"
              />
            </div>
            <router-link to="/riasans" class="main-btn-click ria-btn">
              리아체 보러가기
            </router-link>
          </div>
        </div>

        <div class="main-btn main-btn__chabddag">
          <div class="main-btn__chabddag-wrapper">
            <div class="chabddak-content">
              <h1 class="chab-title">CHAB</h1>
              <h1 class="ddak-title">DDAK</h1>
            </div>
            <router-link to="/chobddag" class="main-btn-click chabddak-btn">
              찹딱체 보러가기
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import url("../assets/css/common.css");
@import url("../assets/css/swiper.css");
@import url("../assets/css/layout.css");
@import url("../assets/css/ui.css");

/* 추가적인 SCSS 스타일은 여기서 정의할 수 있습니다 */
</style>
